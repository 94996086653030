
import useQuasar from 'quasar/src/composables/use-quasar.js';import date from 'quasar/src/utils/date.js';;
import { ref, defineComponent, onMounted } from "vue";
import PostagemColumn from "@/util/table-columns/PostagemColumn";
import PostagemForm from "@/components/admin/postagens/PostagemForm.vue";
import DivisaoService from "@/services/auth/DivisaoService";
import CursoService from "@/services/admin/CursoService";
import TurmaService from "@/services/admin/TurmaService";
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import useList from "@/modules/useList";
import store from "@/store";
import Postagem from "@/interfaces/Postagem";
import BaseService from "@/services/admin/BaseService";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { PostagemForm },
  setup() {
    const $q = useQuasar();
    const router = useRoute();
    const rows = ref<Postagem[]>([]);
    const cursos = ref<Curso[]>([]);
    const turmas = ref<Turma[]>([]);
    const {
      loading,
      filter,
      columns,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList("postagens", PostagemColumn);

    const getPostagensGerais = (): void => {
      loading.value = true;
      BaseService.list("postagens")
        .then((res) => {
          rows.value = res.data.filter((p: Postagem) => p.tipo == "Geral");
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getPostagensTurma = (id: string): void => {
      loading.value = true;
      BaseService.list("postagens")
        .then((res) => {
          let postagensTurmas = res.data.filter(
            (p: Postagem) => p.tipo == "Turma"
          );
          rows.value = postagensTurmas.filter((p: Postagem) => {
            let turmasIds = p.turmas.map((t: Turma) => t.id);
            if (turmasIds.includes(id)) {
              return p;
            }
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getPostagensCurso = (id: string): void => {
      loading.value = true;
      BaseService.list("postagens")
        .then((res) => {
          let postagensCursos = res.data.filter(
            (p: Postagem) => p.tipo == "Curso"
          );
          rows.value = postagensCursos.filter((p: Postagem) => {
            let cursosIds = p.cursos.map((c: Curso) => c.id);
            if (cursosIds.includes(id)) {
              return p;
            }
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getCursos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        TurmaService.list()
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const list = () => {
      if (router.path.includes("turma")) {
        getPostagensTurma(router.params.id.toString());
      } else if (router.path.includes("curso")) {
        getPostagensCurso(router.params.id.toString());
      } else {
        getPostagensGerais();
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const verifyIsFixed = (props: string): boolean => {
      if (props == null) {
        return false;
      }
      const dateNow = new Date();
      const newDate = date.extractDate(props, `YYYY-MM-DD`);

      return newDate > dateNow ? true : false;
    };

    onMounted(() => {
      getCursos();
      getTurmas();

      if (router.path.includes("turma")) {
        getPostagensTurma(router.params.id.toString());
      } else if (router.path.includes("curso")) {
        getPostagensCurso(router.params.id.toString());
      } else {
        getPostagensGerais();
      }
    });

    return {
      rows,
      cursos,
      turmas,
      loading,
      filter,
      columns,
      modalForm,
      router,
      list,
      openModalForm,
      formatData,
      confirmDelete,
      getPostagensGerais,
      getPostagensTurma,
      getPostagensCurso,
      verifyIsFixed,
    };
  },
});
